body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
  width: 100%;
  height: 100px;

  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}
.header-logo {
  width: 145px;
  height: 48px;
  margin-top: 11%;
  margin-left: 8%;
}
.header-text-bold {
  width: 61px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.header-text-bold-demo {
  width: 133px;
  height: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 8%;
}

.header-text-bold-blogs {
  width: 54px;
  height: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: 8%;
}
.header-text-bold-fqs {
  width: 54px;
  height: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: 8%;
}

.header-text-bold-about {
  width: 90px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  flex: none;
  order: 4;
  flex-grow: 0;
  margin-left: 8%;
}

.header-text-margin {
  display: flex;
  margin: 10px;
  padding-top: 2.2%;
  padding-left: 18%;
}

.header-language {
  margin-top: 7%;
  margin-left: 44%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.carousel-margin {
  min-width: 101%;
}
.header-icon {
  width: 101px;
  height: 30px;
  margin-left: 64%;
  margin-top: -8%;
  margin-bottom: 4%;
  color: #000000;
}

.header-language-margin {
  margin-left: 27%;
}

.header-caousel-text {
  position: absolute;
  margin-left: 32%;

  top: 40%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}

.header-caousel-text-loren {
  position: absolute;
  width: 65%;
  height: 60px;
  left: 19%;
  top: 45%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.update {
  height: 573px;

  top: 730px;
}

.update-slider {
  margin-top: 6%;
}

.update-title {
  text-align: center;
  height: 48px;
  left: 563px;
  top: 780px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  color: #000000;
}

.update-slider-margin {
  margin: 2%;
}
.update-slider-box {
  margin-top: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.update-slider-card-box {
  margin: -4%;
  margin-top: 50px;
  width: 87% !important;
  height: 295px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-radius: 10px;
}
.update-slider-card-box-active-dev {
  width: 107% !important;

  background: #ffffff;
  border-radius: 10px;
}

.update-slider-img {
  position: absolute;
  width: 275px;
  height: 180px;
  left: 10px;
  top: 10px;

  border-radius: 10px;
}
.update-slider-card-text {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.update-slider-card-text-des {
  width: 100%;
  margin: 1%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.update-slider-card-video {
  margin: 3%;
}

.update-slider-margin {
  margin-left: 0%;
}

.slick-next:before,
.slick-prev:before {
  font-size: 40px;
}
.update-slider-palyIcon-demo {
  position: absolute;
  font-size: 40px;
  top: 146px;
  margin-left: 106px;
  background: none;
  color: aliceblue;
}

.update-slider-palyIcon {
  position: absolute;
  font-size: 40px;
  top: 146px;
  margin-left: 106px;
  background: none;
  color: aliceblue;
}

.lms-header {
  background: white;
}

.lms-icon-font-size {
  font-size: 70px;
  color: black;
}
.fillicon-shop {
  color: ED2839;
  position: absolute;
  margin: 21px;
  color: black;
  font-size: 26px;
}

.cart {
  color: ED2839;
  position: absolute;
  margin: -19px;
  color: black;
  font-size: 26px;
  margin-top: 40px;
}

.retail-text {
  height: 36px;
  left: 0px;
  top: 40px;
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 24px;
  line-height: 36px;

  color: black;
}
.lms-header-flex {
  display: flex;
  margin-left: 12%;
}

.lms-margin-left {
  margin-left: 13%;
}

.lms-text {
  height: 36px;
  left: 0px;
  top: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #000000;
}

.lms-icon-pos {
  font-size: 70px;
  color: rgb(11, 1, 1);
}

.lmsicon-pos-inside {
  position: absolute;
  margin: 8px;
  color: rgb(8, 1, 1);
  font-size: 50px;
}

.lms-pos-card {
  position: absolute;
  margin: -3px;
  color: rgb(14, 2, 2);
  font-size: 25px;
  margin-top: 40px;
}

.lms-icon-web {
  font-size: 70px;
  color: rgb(11, 1, 1);
}

.lmsicon-web-inside {
  position: absolute;
  margin: 13px;
  margin-left: 1.5%;
  color: rgb(8, 1, 1);
  font-size: 30px;
}

.lms-web-world {
  position: absolute;
  margin: -43px;
  color: rgb(14, 2, 2);
  font-size: 20px;
  margin-top: 22px;
}

.lms-icon-onlinestore {
  font-size: 70px;
  color: rgb(11, 1, 1);
}

.lmsicon-onlinestore-inside {
  position: absolute;
  margin: 21px;
  margin-left: 1.5%;
  color: rgb(8, 1, 1);
  font-size: 23px;
  margin-left: 12px;
}

.lms-onlinestore-cart {
  position: absolute;
  margin: -37px;
  color: rgb(14, 2, 2);
  font-size: 26px;
  margin-top: 20px;
}

#dropDown {
  width: 385px;
  height: 50px;
  background-color: none;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  font-family: "Poppins";
  margin-top: 4%;
  color: #000000;
}

#dropDownvalue {
  width: 300px;
  margin: 3%;
  font-family: "Poppins";
  position: absolute;
  color: #000000;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.lms-select-flex {
  display: flex;
  margin-left: 4%;
}
.dropdown-margin {
  margin: 2%;
}

.lms-select-topic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.lmd-body-content {
  margin-left: 4%;
  margin-right: 4%;
  flex-wrap: wrap;
  display: flex;
}
.lms-body-card {
  margin-left: 2%;
  margin-top: 1%;
  width: 295px;
  height: 295px;
  left: 0px;
  top: 0px;
  background: #f1f1f1;
  border-radius: 10px;
}
.update-slider-palyIcon-body {
  font-size: 40px;
  position: absolute;
  margin-top: 6%;
  margin-left: 8%;
  background: none;
  color: aliceblue;
}

.update-slider-palyIcon-body-corner {
  font-size: 30px;
  position: absolute;
  margin-top: 10.5%;
  margin-left: 17.5%;
  background: none;
  color: aliceblue;
}

.lms-drop-topic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.fqs {
  margin-top: 1%;
}
.fqs-title {
  height: 48px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 3%;
  color: #000000;
}

.fqs-heading {
  display: flex;
  box-sizing: border-box;
  width: 70%;
  height: 82px;
  margin-left: 13%;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}
.fqs-heading-text {
  margin-top: 2%;
  /* text-align: center; */
  margin-left: 2%;
  width: 574px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.fqs-heading-icon {
  margin: 2%;
  font-size: 22px;
  font-weight: 500;
  color: #ed2839;
}
.fqs-heading-sub-text {
  position: absolute;
  width: 60%;
  margin-top: 2%;
  text-align: center;
  margin-left: 6%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #565656;
}
:where(.css-dev-only-do-not-override-acm2ia).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  display: inline-flex;
  align-items: center;
  color: red;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 19px;
}

.fqs-subheading {
  box-sizing: border-box;
  display: flex;
  width: 70%;
  height: 170px;
  margin-left: 13%;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.fqs-heading-icon-sub {
  margin-right: 2%;
  font-size: 23px;
  font-weight: 500;
  color: #ed2839;
}

.footer {
  margin-top: 80px;
  height: 300px;
  background: linear-gradient(86.26deg, #ed2839 1.56%, #b00413 99.39%);
}

.footer-contact {
  margin-left: 11%;
  margin-top: 12%;
}
.footer-text {
  width: 223px;
  height: 23px;
  left: 100px;
  top: 3774px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
}

.footer-text-add {
  position: absolute;
  width: 223px;
  margin-top: 2%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #ffffff;
}

.footer-mid {
  margin-top: 11%;
  margin-left: 10%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
}

.footer-mid-text {
  margin-top: 5%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 540;
  font-size: 18px;
  line-height: 23px;
  margin-left: 10%;
  color: #ffffff;
}

.footer-sm {
  margin-top: 27%;
}
.footer-logo {
  display: flex;
}
.footer-playstore {
  display: flex;
  box-sizing: border-box;
  width: 155px;
  height: 54px;
  left: 1195px;
  top: 3844px;

  border: 2px solid #ffffff;
  border-radius: 4px;
  margin-left: 5%;
}
.playstore {
  margin: 5%;
  margin-left: 2%;
  font-size: 35px;
  color: #ffffff;
  -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
          transform: matrix(1, 0, 0, -1, 0, 0);
}
.footer-play-test-get {
  width: 92px;
  height: 42px;
  left: 1245px;
  top: 3850px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #ffffff;
}
.footer-play-bottom {
  display: flex;
  width: 400px;
}

.footer-social-icons {
  margin: 5%;
  font-size: 30px;
  color: #ffffff;
}

a:-webkit-any-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}
.centerCard {
  color: red;
  margin: 10%;
}

.center {
  color: red;

  margin: 4%;
}
.slick-center {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  margin-left: 0.22%;
  margin-right: 0.1%;
  place-items: center;
}

.img-width {
  height: 180px;
  width: 275px;
  border-radius: 10px;
}

.demoyoutube {
  height: 573px;
}

.slick-prev {
  left: -56px;
  margin-top: -13%;
}

.slick-next {
  right: -75px;
  margin-top: -11%;
}

.youtubeplayer {
  z-index: 100;
  display: flex;
  position: fixed;

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.closeButton {
  position: absolute;
  width: 223px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 540;
  font-size: 25px;
  line-height: 23px;

  color: #e51919;
}
.CloseLogo {
  margin-top: -26px;
  margin-left: 441%;
  font-size: 40px;
  color: red;
}
.abc {
  width: 40px;
  height: 30px;
  color: red;
}

.video-container iframe,
.video-container object,
.video-container embed {
  height: 600px;
  width: 1000px;
}
.lms-header-top-margin {
  height: 100px;
}
/* tab view##################################################################################################################### */
@media only screen and (max-width: 1024px) {
  .video-container iframe,
  .video-container object,
  .video-container embed {
    height: 400px;
    width: 700px;
  }
  .CloseLogo {
    margin-top: -26px;
    margin-left: 304%;
    font-size: 40px;
    color: red;
  }
  .header-text-bold {
    width: 53px;
    height: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .header-text-bold-fqs {
    width: 54px;
    height: 30px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-left: 0%;
  }
  .header-text-bold-about {
    width: 90px;
    height: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    flex: none;
    order: 4;
    flex-grow: 0;
    margin-left: -2%;
  }
  .header-text-bold-blogs {
    width: 54px;
    height: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-left: -2%;
  }

  a:-webkit-any-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 31px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }
  .header-text-bold-demo {
    width: 133px;
    height: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 4%;
  }
  .header-icon {
    width: 101px;
    height: 30px;
    margin-left: 45%;
    margin-top: -20%;
    margin-bottom: 6%;
    color: #000000;
  }
  .header-language {
    margin-top: 18%;
    margin-left: 17%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
  }

  .header-text-margin {
    display: flex;
    /* margin: 10px; */
    padding-top: 6.2%;
    padding-left: 0%;
  }
  .header-logo {
    width: 106px;
    height: 92px;
    margin-top: 10%;
    margin-left: 6%;
  }

  .header-caousel-text-loren {
    position: absolute;
    width: 65%;
    height: 60px;
    left: 12%;
    top: 33%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
  .header-caousel-text {
    position: absolute;
    margin-left: 25%;
    top: 26%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 48px;
    color: #ffffff;
  }
  .update-slider-palyIcon-body-corner {
    font-size: 30px;
    position: absolute;
    margin-top: 14.5%;
    margin-left: 24.5%;
    background: none;
    color: aliceblue;
  }
  .update-slider-palyIcon-body {
    font-size: 40px;
    position: absolute;
    margin-top: 8%;
    margin-left: 13%;
    background: none;
    color: aliceblue;
  }
  .lms-body-card {
    margin-left: 0%;
    margin-top: 1%;
    width: 295px;
    height: 295px;
    left: 0px;
    top: 0px;
    background: #f1f1f1;
    border-radius: 10px;
  }
  .lms-header-flex {
    display: flex;
    margin-left: 1%;
  }
  #dropDown {
    width: 261px;
    height: 45px;
    background-color: none;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    font-family: "Poppins";
    margin-top: 4%;
    color: #000000;
  }
  .slick-center {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  .slick-slide img {
    height: 146px;
    width: 226px;
    border-radius: 7px;
  }

  .update-slider-card-box {
    /* margin: -60%; */
    margin-top: 51px;
    width: 107% !important;
    /* height: 262px; */
    /* left: 0px; */
    /* top: 0px; */
    background: #ffffff;
    border-radius: 10px;
  }

  .footer-contact {
    margin-left: 11%;
    margin-top: 12%;
  }

  .footer-sm {
    margin-top: 28%;
    margin-left: 32%;
  }
  .footer-playstore {
    display: flex;
    box-sizing: border-box;
    width: 200px;
    height: 54px;
    left: 1195px;
    top: 3844px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    margin-left: 10%;
  }
  .footer-mid-text {
    width: 223px;
    margin-top: 9%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 540;
    font-size: 18px;
    line-height: 23px;
    margin-left: 46%;
    color: #ffffff;
  }
  .footer-mid-text {
    margin-top: 9%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 540;
    font-size: 18px;
    line-height: 23px;
    margin-left: 3%;
    color: #ffffff;
  }
  .footer-sm {
    margin-top: 50%;
    margin-left: -26%;
  }
  .footer-play-bottom {
    display: flex;
    width: 353px;
  }
  .footer-playstore {
    display: flex;
    box-sizing: border-box;
    width: 160px;
    height: 54px;
    left: 1195px;
    top: 3844px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    margin-left: 2%;
  }
  .lms-header-top-margin {
    height: 80px;
  }
}

@media only screen and (min-width: 600px) {
  .header-icon-menu {
    display: none;
    /* margin-left: 900%; */
  }
}

/* mobileView view##################################################################################################################### */

@media only screen and (max-width: 600px) {
  :where(.css-dev-only-do-not-override-acm2ia).ant-drawer-right
    > .ant-drawer-content-wrapper {
    top: -2px;
    right: 10%;
    bottom: 0;
    box-shadow: -6px 0 16px 0 rgb(0 0 0 / 8%), -3px 0 6px -4px rgb(0 0 0 / 12%),
      -9px 0 28px 8px rgb(0 0 0 / 5%);
    width: 300px;
  }
  /* .fqs-answer-test {
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #565656;
  }
  .ant-collapse-header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
  } */
  /* :where(.css-dev-only-do-not-override-acm2ia).ant-collapse
    .ant-collapse-content
    > .ant-collapse-content-box {
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #565656;
  } */
  .closeButton {
    position: absolute;
    width: 188px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 540;
    font-size: 25px;
    line-height: 23px;
    color: #e51919;
  }
  .update-slider-palyIcon-demo {
    position: absolute;
    font-size: 36px;
    top: 108px;
    margin-left: 141px;
    background: none;
    color: aliceblue;
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 26%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    margin: 10%;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 27px;
  }
  .padding-eft {
    padding-left: 12px;
  }
  .update-slider-box {
    /* margin-top: 3%; */
    /* padding-left: 14%; */
    padding-right: 21%;
  }
  a:-webkit-any-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 46px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }

  .header-text-bold-about {
    display: none;
  }
  .header-text-bold-fqs {
    display: none;
  }
  .header-text-bold-blogs {
    display: none;
  }
  .header-text-bold-demo {
    display: none;
  }

  .home-dwawer {
    height: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
  }
  :where(.css-dev-only-do-not-override-acm2ia).ant-drawer .ant-drawer-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #ffffff;
    pointer-events: auto;
    margin-left: 0%;
  }

  .menu-icon {
    display: flex;
  }
  .header-icon-menu {
    width: 101px;
    height: 30px;
    margin-left: -32%;
    margin-top: 30%;
    margin-bottom: 6%;
    color: #000000;
  }

  .header-icon {
    width: 101px;
    height: 30px;
    margin-left: -28%;
    margin-top: 30%;
    margin-bottom: 6%;
    color: #000000;
  }
  .header-language {
    display: none;
    margin-top: 18%;
    margin-left: 17%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
  }

  .youtubeplayer {
    z-index: 100;
    display: flex;
    position: fixed;
    top: 50%;
    left: 47%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .update-slider-palyIcon-body {
    font-size: 40px;
    position: absolute;
    margin-top: 22%;
    margin-left: 41%;
    background: none;
    color: aliceblue;
  }
  .lms-body-card {
    margin-left: -2%;
    margin-top: 1%;
    width: 102%;
    height: 276px;
    left: 0px;
    top: 0px;
    background: #f1f1f1;
    border-radius: 10px;
  }
  .lms-header-top-margin {
    height: 43px;
  }
  .img-width {
    margin-left: 5%;
    height: 190px;
    width: 313px;
    border-radius: 10px;
  }
  .update-slider-card-text {
    margin-left: 4%;
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #000000;
  }
  .update-slider-palyIcon-body-corner {
    display: none;
  }
  .update-slider-card-text-des {
    padding-left: 4%;
    /* margin-left: 3%; */
    width: 100%;
    margin: 1%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
  .footer-playstore {
    display: flex;
    box-sizing: border-box;
    width: 160px;
    height: 54px;
    left: 1195px;
    top: 3844px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    margin-left: 2%;
    margin-top: 16%;
  }
  :where(.css-dev-only-do-not-override-acm2ia).ant-col-8 {
    display: block;
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
    margin-top: 5%;
  }

  .footer-social-icons {
    margin: 0.5%;
    font-size: 23px;
    color: #ffffff;
  }
  .footer-play-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
  }
  .footer-logo {
    margin-right: 10%;
    display: flex;
    flex-wrap: wrap;
  }

  .footer-text {
    width: 197px;
    height: 23px;
    left: 100px;
    top: 3774px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 23px;
    color: #ffffff;
  }
  .footer-text-add {
    position: absolute;
    width: 141px;
    margin-top: 2%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 23px;
    color: #ffffff;
  }
  .footer-mid-text {
    margin-top: 12%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 540;
    font-size: 12px;
    line-height: 21px;
    margin-left: 22%;
    color: #ffffff;
  }
  .CloseLogo {
    margin-top: -26px;
    margin-left: 147%;
    font-size: 40px;
    color: red;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    height: 200px;
    width: 300px;
  }
  .update-title {
    text-align: center;
    height: 15px;
    left: 563px;
    top: 780px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 46px;
    color: #000000;
  }
  .demoyoutube {
    height: 391px;
  }
  .update-slider-card-text-des-demo {
    display: none;
    margin-left: 28%;
    width: 100%;
    margin: 2%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .update-slider-card-text-demo {
    margin-left: 28%;
    width: 50%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .update-slider-card-box {
    margin-left: -18%;
    margin-right: 122px;
    -webkit-animation-timing-function: 1%;
            animation-timing-function: 1%;
    /* margin-top: 48px; */
    /* width: 93% !important; */
    height: 190px;
    /* left: 0px; */
    /* top: 0px; */
    background: #ffffff;
    border-radius: 10px;
  }
  .slick-slide img {
    margin-left: 27%;
    height: 129px;
    width: 150px;
    border-radius: 7px;
  }
  .update-slider-card-video {
    margin: -50%;
    margin-top: 2%;
  }

  .update-slider-card {
    margin: -2%;
    margin-top: 2%;
  }
  .slick-center {
    /* transform: scale(1.4); */
    /* margin-left: 0.22%; */
    /* margin-right: 0.1%; */
    place-items: center;
  }
  .header-text-bold {
    display: none;
    width: 50px;
    height: 30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 38px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .header-logo {
    width: 140px;
    height: 85px;
    margin-top: 10%;
  }
  .header-caousel-text-loren {
    position: absolute;
    width: 79%;
    height: 60px;
    left: 12%;
    top: 21%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
  .header-caousel-text {
    position: absolute;
    margin-left: 17%;
    top: 16%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 48px;
    color: #ffffff;
  }
  .lms-select-flex {
    display: block;
    margin-left: 4%;
  }
  #dropDown {
    width: 310px;
    height: 45px;
    background-color: none;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Poppins";
    margin-top: 1%;
    color: #000000;
  }
  .dropdown-margin {
    margin: 3%;
  }
  .lmsicon-onlinestore-inside {
    position: absolute;
    margin: 21px;
    margin-left: 1.5%;
    color: rgb(8, 1, 1);
    font-size: 16px;
    margin-left: 12px;
  }
  .lms-icon-onlinestore {
    font-size: 59px;
    color: rgb(11, 1, 1);
  }
  .lms-onlinestore-cart {
    position: absolute;
    margin: -31px;
    color: rgb(14, 2, 2);
    font-size: 17px;
    margin-top: 20px;
  }
  .lms-icon-web {
    font-size: 61px;
    color: rgb(11, 1, 1);
  }
  .lmsicon-web-inside {
    position: absolute;
    margin: 12px;
    margin-left: 4%;
    color: rgb(8, 1, 1);
    font-size: 23px;
  }

  .lms-web-world {
    position: absolute;
    margin: -39px;
    color: rgb(14, 2, 2);
    font-size: 16px;
    margin-top: 18px;
  }
  .lmsicon-pos-inside {
    position: absolute;
    margin: 8px;
    color: rgb(8, 1, 1);
    font-size: 40px;
  }
  .lms-icon-pos {
    font-size: 56px;
    color: rgb(11, 1, 1);
  }
  .lms-pos-card {
    position: absolute;
    margin: -3px;
    color: rgb(14, 2, 2);
    font-size: 17px;
    margin-top: 35px;
  }
  .lms-icon-font-size {
    font-size: 60px;
    color: black;
  }
  .cart {
    color: ED2839;
    position: absolute;
    margin: -19px;
    color: black;
    font-size: 20px;
    margin-top: 40px;
  }

  .fillicon-shop {
    color: ED2839;
    position: absolute;
    margin: 21px;
    color: black;
    font-size: 17px;
  }

  .retail-text {
    height: 36px;
    left: 0px;
    top: 40px;
    font-family: "Poppins";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 36px;
    color: black;
  }
  .lms-text {
    height: 36px;
    left: 0px;
    top: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    color: #000000;
  }

  .lms-margin-left {
    margin-left: 6%;
  }
  .lms-header-flex {
    display: flex;
    margin-left: 0%;
  }
}

.fqs-header-margin {
  margin-left: 12%;
  width: 80%;
  margin-right: 12%;
}
.pane-header-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
}
.fqs-answer-test {
  width: 80%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #565656;
}

